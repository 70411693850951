import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import Hero from '../../components/Hero/Hero';
import ContactForm from '../../components/ContactForm/ContactForm';

const SContact = styled.main`
  width: 100%;
  .section {
    display: block !important;
    .form-wrapper {
      margin: 0 auto;
      margin-top: 2rem;
      @media (max-width: 768px) {
        margin: 0;
        margin-top: 2rem;
      }
    }
  }
`;

const Contact = ({ pageTitle }) => {
  const line1 = `Got a crazy Idea? Let's make it happen,`;
  const line2 = `Hit me up.`;
  const paragraph = `Looking to hire? or you're just interested in what I do? Sure, feel free to contact me by filling in the form.`;
  // const buttonText = `Back to the homepage`;
  const extra = `Or contact me via Twitter, you can find me by clicking on the twitter icon down there in the footer section.`;

  useEffect(() => {
    document.title = ` ${pageTitle} · Imran`;
  });

  return (
    <SContact>
      <Container>
        <div className='top --no-reverse'>
          <div className='section'>
            <Hero
              pageTitle={pageTitle + '.'}
              line1={line1}
              line2={line2}
              paragraph={paragraph}
              buttonText={''}
              buttonPath={'/'}
              extra={extra}
            />
          </div>
          <div className='section'>
            <ContactForm />
          </div>
        </div>
      </Container>
    </SContact>
  );
};

export default Contact;
