import React, { useEffect } from 'react';
import Image from '../../components/Image/Image';
import resumeImage from '../../assets/images/05.png';
// import profileImage from '../../assets/images/profile.jpg';
// import Container from '../../components/Container/Container';
import { Container } from '@material-ui/core';
import Hero from '../../components/Hero/Hero';
import Title from '../../components/Title/Title';
import Paragraph from '../../components/Paragraph/Paragraph';
// import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import {
  htmlImage,
  cssImage,
  javascriptImage,
  reactImage,
  sassImage,
  nodejsImage,
  scomponentsImage,
  gatsbyImage,
  mongodbImage,
  vscodeImage,
  postmanImage,
  adobexdImage,
  githubImage,
  firebaseImage,
  reduxImage,
  socketioImage,
  expressImage,
  gsapImage,
  miroImage,
  figmaImage,
  notionImage,
  framerImage,
  canvaImage,
  asanaImage
} from './aboutAssets';
import SAbout from './AboutStyled';
import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const Resume = ({ pageTitle }) => {
  const line1 = `Humble, `;
  const line2 = `Kaizen.`;
  const paragraph = `A product leader, growth hacker, UX/UI & tech enthusiast, Innvestor, Business Student, Strategist, Marketer, Writer, Batman. Okay maybe not the last one. I believe in build-measure-learn methodology and love to brainstorm, roadmap, experiment and bring ideas to life. `;
  // const buttonText = `Are you hiring?`;

  useEffect(() => {
    document.title = ` ${pageTitle} · Imran`;
  });

  return (
    <SAbout>
      <Container>
        <div className='top'>
          <div className='section'>
            <Hero
              pageTitle={pageTitle + '.'}
              line1={line1}
              line2={line2}
              paragraph={paragraph}
              buttonText={''}
              buttonPath={'/hire'}
              scroller
              scrollButtonText='A little info about me'
            />
          </div>
          <div className='section'>
            <Image src={resumeImage} />
          </div>
        </div>
        <div className='mid' id='mid'>
          <div className='rofa'>
            <div className='item'>
              <Title pageTitle>Who am I?</Title>
              <Title sectionTitle>
                <div className='line-wrapper'>
                  <div className='line'>Mohammed</div>
                </div>
                <div className='line-wrapper'>
                  <div className='line'>Imran.</div>
                </div>
              </Title>
              <Paragraph>
                A Product Manager with 5 years of hands-on experience leading successful teams, ensuring high-quality delivery, exercising collaborative team & stakeholder management.
                My professional and personal experiences have taught me the following about myself - My gut instinct is great, 
                but I seek data for my decisions. I stay calm when faced with adversity. Love to win, but helping the team win gives me greater joy. I put the customer at the center of all that I do, Goal Digger :)
              </Paragraph>
              <Button
                variant='outlined'
                color='primary'
                endIcon={<ExternalLinkIcon width='16px' />}
                href={process.env.PUBLIC_URL + '/docs/imran-resume.pdf'}
                target='_blank'
                size='large'
                className='custom-button'
              >
                Resume pdf file
              </Button>
            </div>
          </div>
          <div className='skills'>
            <div className='skills__header'>
              <Title pageTitle>Skills.</Title>
              <Title sectionTitle>
                <div className='line-wrapper'>
                  <div className='line'>Technical</div>
                </div>
                <div className='line-wrapper'>
                  <div className='line'>Skills.</div>
                </div>
              </Title>
              <Paragraph>
                Here you will find a showcase of some of the technologies I work
                with.
              </Paragraph>
            </div>
            <div className='skills__list'>
              <div className='skills__list__set'>
                <h3>Front-end technologies.</h3>
                <ul>
                  <li className='item'>
                    <img src={htmlImage} alt='HTML' />
                    <h4 className='item__title'>HTML</h4>
                  </li>
                  <li className='item'>
                    <img src={cssImage} alt='CSS' />
                    <h4 className='item__title'>CSS</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={javascriptImage} alt='JavaScript' />
                    <h4 className='item__title'>JavaScript</h4>
                   
                  </li>
                  <li className='item'>
                    <img src={gsapImage} alt='GSAP' />
                    <h4 className='item__title'>GSAP</h4>
                    
                  </li>
                </ul>
                <ul>
                  <li className='item'>
                    <img src={reactImage} alt='ReactJS' />
                    <h4 className='item__title'>React JS</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={sassImage} alt='Sass' />
                    <h4 className='item__title'>Sass</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={gatsbyImage} alt='Gatsby' />
                    <h4 className='item__title'>Gatsby</h4>
                    
                  </li>
                </ul>
              </div>
              <div className='skills__list__set'>
                <h3>Back-end technologies.</h3>
                <ul>
                  <li className='item'>
                    <img src={nodejsImage} alt='NodeJS' />
                    <h4 className='item__title'>Node JS</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={expressImage} alt='Express JS' />
                    <h4 className='item__title'>Express JS</h4>
                   
                  </li>
                  <li className='item'>
                    <img src={mongodbImage} alt='MongoDB' />
                    <h4 className='item__title'>MongoDB</h4>
                   
                  </li>
                </ul>
              </div>
              <div className='skills__list__set'>
                <h3>Software & tools.</h3>
                <ul>
                  <li className='item'>
                    <img src={vscodeImage} alt='VSCode' />
                    <h4 className='item__title'>VSCode</h4>
                  </li>
                  <li className='item'>
                    <img src={notionImage} alt='Miro' />
                    <h4 className='item__title'>Notion</h4>
                  </li>

                  <li className='item'>
                    <img src={postmanImage} alt='Postman' />
                    <h4 className='item__title'>Postman</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={asanaImage} alt='Asana' />
                    <h4 className='item__title'>Asana</h4>
                    
                  </li>
                </ul>
                <ul>
                  <li className='item'>
                    <img src={adobexdImage} alt='Adobe XD' />
                    <h4 className='item__title'>Adobe XD</h4>
                    
                  </li>
                  
                  <li className='item'>
                    <img src={canvaImage} alt='Canva' />
                    <h4 className='item__title'>Canva</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={figmaImage} alt='Figma' />
                    <h4 className='item__title'>Figma</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={githubImage} alt='GitHub' />
                    <h4 className='item__title'>GitHub</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={miroImage} alt='Miro' />
                    <h4 className='item__title'>Miro</h4>
                  </li>
                 
                </ul>
              </div>
              <div className='skills__list__set'>
                <h3>and sometimes these as well.</h3>
                <ul>
                  <li className='item'>
                    <img src={socketioImage} alt='Socket IO' />
                    <h4 className='item__title'>Socket IO</h4>
                   
                  </li>
                </ul>
                <ul>
                  <li className='item'>
                    <img src={reduxImage} alt='Redux' />
                    <h4 className='item__title'>Redux</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={framerImage} alt='Framer' />
                    <h4 className='item__title'>Framer</h4>
                    
                  </li>
                  <li className='item'>
                    <img src={firebaseImage} alt='Firebase' />
                    <h4 className='item__title'>Firebase</h4>
                    
                  </li>
                </ul>
                <ul>
                  <li className='item'>
                    <img src={scomponentsImage} alt='SComponents' />
                    <h4 className='item__title'>SComponents</h4>
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='bot'>
          <div className='cta'>
            <Title sectionTitle>
              <div className='line-wrapper'>
                <div className='line'>I've put in all of the skills</div>
              </div>
              <div className='line-wrapper'>
                <div className='line'>and here's what I've made out of it.</div>
              </div>
            </Title>
            <Paragraph className='text'>
              The projects I am going to show you are both real world and personal side projects that act as a demonstration of a
              skill or certain amount of skills.
            </Paragraph>
            <Button
              variant='outlined'
              component={Link}
              to='/projects'
              endIcon={<ArrowRightIcon width='16px' />}
              size='large'
              className='custom-button'
            >
              Have a look
            </Button>
          </div>
        </div>
      </Container>
    </SAbout>
  );
};

export default Resume;
