import CoronaTrackerPng from '../../assets/images/CoronaTracker.png';
import guviProjectPng from '../../assets/images/guviProject.png';
import imranPng from '../../assets/images/imran.png';
import NotAmazonPng from '../../assets/images/NotAmazon.png';
import degoPng from '../../assets/images/dego.png';
import surveyBucky from '../../assets/images/surveybucky.png';

export const projects = [
  {
    id: 1,
    title: 'Covid-19 Tracker. (Completed)',
    subtitle: 'Covid-19 Global tracker.',
    about: `Covid-19 Global Tracker allows you to get the information from across the world. This is one of my first react applications. It also allows you to get graphical and chart representation of the same.
    
    The dropdown menu helps you to fetch whichever country's data you'd like to see.`,
    image: CoronaTrackerPng,
    logo: null,
    skills: [
      'JavaScript',
      'React.js',
      'Material UI',
      'Chart Js',
      'React Router'
    ],
    previewUrl: 'https://thirsty-williams-22b519.netlify.app',
    repoUrl: 'https://github.com/itsimranmd/Covid-19-tracker',
    isCompleted: true,
  },
  {
    id: 2,
    title: 'Guvi iOS Prototype. (Completed)',
    subtitle: 'Not your ordinary learning application.',
    about: `Guvi's iOS prototype has been made on Framer. This is a core design project that I have taken up by myself. It gives you the ability to take notes, code on Code Playground X and browse other student's user feed and code on the go.`,
    image: guviProjectPng,
    logo: null,
    skills: [
   'Adobe XD',
   'Framer',
    'Sketch'
    ],
    previewUrl: 'https://linktr.ee/itsimranmd',
    repoUrl: 'https://github.com/itsimranmd/Guvi-iOS-Prototype',
    isCompleted: true,
  },
  {
    id: 3,
    title: 'Imran. (Completed)',
    subtitle: 'A remake of my portfolio website.',
    about: `My official portfolio website, built with React.js, it has 4 main routes: Home, About, Projects and Contact. I made sure to keep it simple and straight forward, I've also coded some reveal animations and added a dark/light theme switching feature.`,
    image: imranPng,
    logo: null,
    skills: [
      'JavaScript',
      'React.js',
      'Redux',
      'SCSS',
      'React Router',
      'Styled Components',
      'GSAP',
    ],
    previewUrl: 'https://imranmd.com',
    repoUrl: 'https://notebook.zoho.in/public/notes/s5srs12fcc49a6fb14824a8f3ef9c1b79c5d8',
    isCompleted: true,
  },
  {
    id: 4,
    title: 'Not Amazon. (Completed)',
    subtitle: 'A full stack E-commerce Project.',
    about: `This is one of my first MERN stack project, It's a side project that I've been working on. I took this up from wireframing , prototyping to actual design, code and maintainability. It uses JWT for Authorization I've also integrated Paypal for payments. Try out using Id: testinguser@imranmd.com Pass: testinguser123 `,
    image: NotAmazonPng,
    logo: null,
    skills: [
      'JavaScript',
      'React.js',
      'Redux',
      'CSS',
      'React Router',
      'MongoDb',
      'Node',
      'ExpressJs',
      'HTML',
    ],
    previewUrl: 'https://notamazonapp.herokuapp.com/',
    repoUrl: 'https://github.com/itsimranmd/NotAmazon',
    isCompleted: true,
  },
  {
    id: 5,
    title: 'Dego. (Completed)',
    subtitle: 'A furniture shopping app.',
    about: `This is my first design project. "Designed products on the go". I loved building this one. I've ensured the animations and transitions are smooth and just right.`,
    image: degoPng,
    logo: null,
    skills: [
  'Adobe XD',
  'Protopie'
    ],
    previewUrl: 'https://youtu.be/3MEBDPj8Xww',
    repoUrl: 'https://github.com/itsimranmd/Dego-assets',
    isCompleted: true,
  },
  {
    id: 6,
    title: 'Survey Bucky. (Completed)',
    subtitle: 'A Survey app.',
    about: `Survey bucky is an unrealistic App that I had picked up long time ago to demonstrate my Product design and development strategies. This is made for Android. The whole idea was to improve the UX and User retention on the app. I've kept the design extremely minimilistic.`,
    image: surveyBucky,
    logo: null,
    skills: [
    ],
    previewUrl: 'https://medium.com/@itsimranmd/survey-bucky-project-12b2dc119929',
    repoUrl: 'https://github.com/itsimranmd/SurveyBucky-design-assets',
    isCompleted: true,
  }
];
