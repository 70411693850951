import Contact from '../views/Contact/Contact';
import Home from '../views/Home/Home';
import About from '../views/About/About';
import Projects from '../views/Projects/Projects';

const routes = [
  { path: '/home', name: 'Home', Component: Home },
  { path: '/projects', name: 'Projects', Component: Projects },
  { path: '/about', name: 'About', Component: About },
  { path: '/contact', name: 'Contact', Component: Contact }
];

export { routes as default };
