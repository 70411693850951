import React from 'react';
import styled from 'styled-components';
import Divider from '../Divider/Divider';
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as MediumIcon } from '../../assets/icons/book-open.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';

 




const SSocial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    color: gray;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-inline-end: 0.5rem;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .icons {
    display: flex;
    .icon {
      color: ${({ theme }) => theme.colors.secondary};
      display: flex;
      padding: 0.75rem;
      @media (max-width: 1200px) {
        padding: 0.6rem;
      }
      cursor: pointer;
      svg {
        width: 18px;
        height: 18px;
        transition: all ease-in-out 250ms;
      }
      &:hover {
        svg {
          color: ${({ theme }) => theme.colors.artery};
        }
      }
    }
  }
`;

const Social = () => {
  return (
    <SSocial>
      <p>Find me</p>
      <Divider vertical />
      <div className='icons'>
        <a
          href='https://github.com/itsimranmd'
          target='_blank'
          rel='noopener noreferrer'
          className='icon'
        >
          <GithubIcon />
        </a>
        <a
          href='https://twitter.com/yeahthatnerd'
          target='_blank'
          rel='noopener noreferrer'
          className='icon'
        >
          <TwitterIcon />
        </a>
        <a
          href='https://levelup.gitconnected.com/how-to-improve-user-experience-the-best-way-to-loading-screen-ux-in-react-ccec73de1d66?source=friends_link&sk=fbd0ae2f5406dfb27c07d6c08a484a46'
          target='_blank'
          rel='noopener noreferrer'
          className='icon'
        >
          <MediumIcon />
        </a>

        <a
          href='https://www.linkedin.com/in/mohammed-imran-850a44145/'
          target='_blank'
          rel='noopener noreferrer'
          className='icon'
        >
          <LinkedInIcon />
        </a>
 
        <a
          href='https://www.instagram.com/itsimranmd'
          target='_blank'
          rel='noopener noreferrer'
          className='icon'
        >
          <InstagramIcon />
        </a>
 
      </div>
    </SSocial>
  );
};

export default Social;
