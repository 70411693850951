import React, { useEffect } from 'react';
import styled from 'styled-components';
import Image from '../../components/Image/Image';
import homeImage from '../../assets/images/03.png';
// import Container from '../../components/Container/Container';
import { Container } from '@material-ui/core';
import Hero from '../../components/Hero/Hero';
// import Paragraph from '../../components/Paragraph/Paragraph';
// import Title from '../../components/Title/Title';
// import { Link } from 'react-router-dom';
import { ReactComponent as CoffeeIcon } from '../../assets/icons/coffee.svg';

const SHome = styled.div`
  .mid {
    .about {
      display: grid;
      grid-column-gap: 6rem;
      grid-row-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      a {
        text-decoration: underline;
        transition: all ease-in-out 250ms;
        font-weight: 700;
        &:hover {
          color: ${({ theme }) => theme.colors.artery};
        }
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      .item {
        .counter {
          font-size: 3rem;
          @media (max-width: 1200px) {
            font-size: 2.75rem;
          }
          @media (max-width: 992px) {
            font-size: 2.5rem;
          }
          @media (max-width: 768px) {
            font-size: 2.25rem;
          }
          @media (max-width: 576px) {
            font-size: 2rem;
          }
          color: ${({ theme }) => theme.colors.artery};
          font-family: 'Alegreya';
        }
        .text {
          color: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }
`;

const Home = ({ pageTitle }) => {
  // Page content
  const line1 = `Hey there, Welcome`;
  const line2 = `to my Portfolio.`;
  const paragraph = `This is Imran, I'm a Product leader who has an eye for creating aesthetic, powerful and scalable products.`;
  // const buttonText = `See some stuff I built`;

  useEffect(() => {
    document.title = ` ${pageTitle} · Imran`;
  });

  return (
    <SHome>
      <Container>
        <div className='top --reverse-row'>
          <div className='section'>
            <Hero
              pageTitle={pageTitle + '.'}
              line1={line1}
              line2={line2}
              paragraph={paragraph}
              buttonText={`Start here!`}
              buttonPath={'/about'}
              buttonIcon={<CoffeeIcon width='18px' />}
              withSocial
            />
          </div>
          <div className='section'>
            <Image src={homeImage} />
          </div>
        </div>
      </Container>
    </SHome>
  );
};

export default Home;
