import styled from 'styled-components';

const ProjectsList = styled.div`
  width: 100%;
  max-width: 1000px;
`;

export const Styled = {
  ProjectsList,
};
